import DashboardGuard from "@/modules/dashboard/helpers/DashboardGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/",
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: () => import("@/modules/dashboard/views/TheDashboard.vue"),
        beforeEnter: [
          OnboardingGuard.withCompleteOnboarding,
          DashboardGuard.withNoJobs,
        ],
        meta: { title: "common.route.dashboard.meta" },
      },
    ],
  },
];
