import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { WebSocketService } from "@/core/shared/services/WebSocket/WebSocketService";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

import i18n from "@/i18n";
import { Onboarding } from "@/modules/onboarding/shared/Onboarding";
import { useMarketplace } from "@/shared/composables/Marketplace";

export default class OnboardingGuard {
  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withCompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    //This should never happen, but just in case.
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    /**
     * If we were able to retrieve the user information with the token we have, it means the account is 2FA verified.
     * We can now authenticate against the websocket if we are not authenticated yet.
     */
    if (WebSocketService.isConnected() && !WebSocketService.isAuthenticated()) {
      WebSocketService.authenticate();
    }
    i18n.global.locale.value = useMarketplace().updateLocale(
      String(accountInfo.address_country_code)
    );

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const position = accountInfo?.onboard_position.toString();
    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    if (Object.keys(sequence).includes(position)) {
      return next({
        name: Reflect.get(sequence, position),
      });
    }

    next();
  }

  static async withIncompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    //This should never happen, but just in case.
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    i18n.global.locale.value = useMarketplace().updateLocale(
      String(accountInfo.address_country_code)
    );

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    //The onboarding is completed, redirect to the dashboard.
    if (accountInfo.onboard_position > Onboarding.APPLICATION_SENT) {
      return next({
        name: "Dashboard",
      });
    }

    const sequence = {
      1: "PersonalInformation", //1
      2: "AboutMe", //2
      3: "SocialNetworks", //3
      4: "Categories", //4
      5: "SamplePhoto", //5
      6: "SampleVideo", //6
      7: "Disclaimer", //7
      8: "Summary", //8
      9: "ApplicationSent", //9
    };
    const sequenceId = to.meta.stepperPosition as number;
    //Tries to access a hide step
    if (
      sequenceId < Onboarding.APPLICATION_SENT &&
      sequenceId > Onboarding.CATEGORIES &&
      accountInfo.onboard_position < Onboarding.APPLICATION_SENT &&
      accountInfo.onboard_position > Onboarding.CATEGORIES
    ) {
      return next({
        name: "Categories",
      });
    }
    //The user tries to access a step higher than the current step, redirect to the current step.
    if (sequenceId > accountInfo.onboard_position) {
      return next({
        name: Reflect.get(sequence, accountInfo.onboard_position),
      });
    }
    //The user submitted his application, he can't edit anymore.
    if (
      sequenceId < Onboarding.APPLICATION_SENT &&
      accountInfo.onboard_position === Onboarding.APPLICATION_SENT
    ) {
      return next({
        name: "ApplicationSent",
      });
    }

    //Otherwise, the user can access the page.
    next();
  }
}
