import { useWebSocketAuthenticationStore } from "@/store/WebSocketAuthenticationStore";
import { useWebSocketStore } from "@/store/WebSocketStore";

import type { DsIAuthService } from "@devsalsa/vue-core";

import { container } from "tsyringe";

export interface WebSocketMessage {
  type: string;
  data: Record<string, unknown>;
}

export class WebSocketService {
  static isConnected() {
    return useWebSocketStore().socket.isConnected;
  }

  static isAuthenticated() {
    return useWebSocketAuthenticationStore().isAuthenticated;
  }

  static authenticate() {
    const authService = container.resolve<DsIAuthService>("AuthService");
    this.sendMessage({
      type: "auth",
      data: {
        token: authService.getToken(),
      },
    });
  }

  static sendMessage(message: WebSocketMessage) {
    useWebSocketStore().$socket?.sendObj(message);
  }
}
