import AccountService from "@/core/modules/account/services/AccountService";

import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export default class DashboardGuard {
  static async withNoJobs(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    const stats = await AccountService.getStats();
    if (
      stats.nr_jobs === 0 &&
      stats.campaigns_approved === 0 &&
      stats.campaigns_invited === 0
    ) {
      return next({
        name: "Marketplace",
      });
    }

    to.meta.stats = stats;
    next();
  }
}
