import CampaignGuard from "@/modules/campaign/helpers/CampaignGuard";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";

export default [
  {
    path: "/campaign",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      CampaignGuard.withJobTaken,
    ],
    children: [
      {
        path: "",
        redirect: { name: "Marketplace" },
      },
      {
        path: ":campaignId(\\d+)",
        name: "CampaignSummary",
        component: () => import("@/modules/campaign/views/CampaignSummary.vue"),
        meta: {
          title: "modules.campaign.summary.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/unavailable",
        name: "CampaignUnavailable",
        component: () =>
          import("@/modules/campaign/views/CampaignUnavailable.vue"),
        meta: {
          title: "modules.campaign.unavailable.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/uncertified",
        name: "CampaignUncertified",
        component: () =>
          import("@/modules/campaign/views/CampaignUncertified.vue"),
        meta: {
          title: "modules.campaign.uncertified.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/under-level",
        name: "CampaignUnderLevel",
        component: () =>
          import("@/modules/campaign/views/CampaignUnderLevel.vue"),
        meta: {
          title: "modules.campaign.underLevel.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/profile-mismatch",
        name: "CampaignMismatch",
        component: () =>
          import("@/modules/campaign/views/CampaignMismatch.vue"),
        meta: {
          title: "modules.campaign.mismatch.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/job-limit",
        name: "CampaignJobLimit",
        component: () =>
          import("@/modules/campaign/views/CampaignJobLimit.vue"),
        meta: {
          title: "modules.campaign.underLevel.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/applied",
        name: "CampaignApplied",
        component: () => import("@/modules/campaign/views/CampaignApplied.vue"),
        meta: {
          title: "modules.campaign.underLevel.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/completed-applicants",
        name: "CampaignCompletedApplicants",
        component: () =>
          import("@/modules/campaign/views/CampaignCompletedApplicants.vue"),
        meta: {
          title: "modules.campaign.underLevel.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/application-exists",
        name: "CampaignApplicationExists",
        component: () =>
          import("@/modules/campaign/views/CampaignApplicationExists.vue"),
        meta: {
          title: "modules.campaign.underLevel.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/processing",
        name: "CampaignPaymentProcessing",
        component: () =>
          import("@/modules/campaign/views/CampaignPaymentProcessing.vue"),
        meta: {
          title: "modules.campaign.processing.meta",
        },
      },
      {
        path: ":campaignId(\\d+)/failed",
        name: "CampaignPaymentFailed",
        component: () =>
          import("@/modules/campaign/views/CampaignPaymentFailed.vue"),
        meta: {
          title: "modules.campaign.paymentFailed.meta",
        },
      },
    ],
  },
];
